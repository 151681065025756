import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

import PublicationOrDocumentLinkTemplate from "../../templates/common/PublicationOrDocumentLinkTemplate";

export default function PublicationLink({className, title, link, document}) {
    return (
        <PublicationOrDocumentLinkTemplate
            className={className}
            title={title}
            link={link}
            document={document} />
    );
}

PublicationLink.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    document: PropTypes.string
};

export const query = graphql`
    fragment PublicationLink on Publication {
        id
        title
        link
        document {
            publicURL
        }
    }
`;
